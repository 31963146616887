import React from "react"
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import SEO from "../components/seo"
import Layout from '../components/Layout/Layout'

const Template = ({ data, location }) => {
  const brand = data.taxonomyTermBrand
  return (
    <Layout location={location}>
      <SEO title={brand.name} />
      <section className="container mx-auto px-5">
        {brand.relationships.field_logo?.localFile &&
          <div
            className="mx-auto max-w-xs mt-12 mb-12"
          >
            <Img className="max-w-sm" fluid={
              brand.relationships.field_logo.localFile.childImageSharp.fluid
            } />
          </div>
        }
        <div className="page-title mb-0">{brand.name}</div>
        {brand.field_address &&
          <div>{brand.field_address.city}, {brand.field_address.state}</div>
        }
        {brand?.description?.value &&
          <div
            className="mt-8"
            dangerouslySetInnerHTML={{ __html: brand.description.value }}>
          </div>
        }
        <div>
          <a href={brand.field_website.uri}
            target="_blank" rel="noopener noreferrer">
            {brand.field_website.uri}
          </a>
        </div>
      </section>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Template;

export const query = graphql`
  query($brandId: String!) {
    taxonomyTermBrand(id: { eq: $brandId }) {
      id
      drupal_internal__tid
      name
      path {
          alias
      }
      field_website {
        uri
      }
      field_address {
        city: locality
        state: administrative_area
      }
      description {
        value
      }
      relationships {
        field_logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 350, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }`